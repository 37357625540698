<template>
  <div>
  <!-- <div><TickerTape id="tickerTape"/></div> -->
  <!-- <ModelHomePopup /> -->
  <div id="home_wrapper" class="scroller">
    <LoadingComponent v-if="isLoading" :class="{ 'loading': isLoading, 'hidden': !isLoading }"/>
    
    <b-modal id="nourishModal" size="lg" hide-footer class="nourishModal">
        <div>
            <img src="/img/tickBanner.jpg" class="img-fluid"/>
        </div>
    </b-modal>
    <div id="home" class="position-relative bg-grey">
      
      <HeroNew />

      <NourishedNew 
      :videoSrc="videoSrc"
      :previewVideoSrc="previewVideoSrc"
      />

      <Sights id="sights"/>

      <VirtualPresentationTownDetached id="virtual-presentation" />

      <GreaterHeights id="greater-heights"/>

      <Southbay id="southbay"/>

      <Wasaga id="wasaga"/>

      <SouthbayAmenities id="amenities"/>

      <Collingwood id="collingwood"/>

      <HeightenedPerspective id="heightened-perspective"/>

      <AppFooter />

      <PresentationCenter id="presentation"/>
    </div>
  </div>
  </div>
</template>

<script>
import { gsap } from "gsap";
import { ScrollSmoother } from "gsap/ScrollSmoother";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { SplitText } from "gsap/SplitText";
import jq from 'jquery';
import ModelHomePopup from '@/components/ModelHomePopup';

export default {
  data() {
    return {
      videoSrc: '/img/061623_SOUTHBAY_FINAL.mp4',
      previewVideoSrc: '/img/Loopforvideo.mp4',
      isLoading: true,
      scrollSmoother_home: null,
      textAnimation_home: null,
      fadeInUpElement_home: null,
      fadeInUpElement2_home: null,
    };
  },
  components: {
    Hero: () => import("@/components/Hero"),
    TickerTape: () => import("@/components/TickerTape"),
    HeroNew: () => import("@/components/HeroNew"),
    Nourished: () => import("@/components/Nourished"),
    NourishedNew: () => import("@/components/NourishedNew"),
    Sights: () => import("@/components/Sights"),
    VirtualPresentationTownDetached: () => import("@/components/VirtualPresentationTownDetached"),
    GreaterHeights: () => import("@/components/GreaterHeights"),
    Southbay: () => import("@/components/Southbay.vue"),
    Wasaga: () => import("@/components/Wasaga"),
    SouthbayAmenities: () => import("@/components/SouthbayAmenities"),
    Collingwood: () => import("@/components/Collingwood"),
    HeightenedPerspective: () => import("@/components/HeightenedPerspective"),
    AppFooter: () => import("@/components/AppFooter"),
    PresentationCenter: () => import("@/components/PresentationCenter"),
    LoadingComponent: () => import("@/components/LoadingComponent"),
    ModelHomePopup: () => import("@/components/ModelHomePopup"),
    
},
  mounted() {
    gsap.registerPlugin(ScrollTrigger, ScrollSmoother, SplitText);
    ScrollTrigger.refresh();
    setTimeout(() => {
      this.isLoading = false;
      this.initscrollSmoother_homePage();
      // this.initImgElReveal_homePage();
      this.panoReveal();
      // this.$bvModal.show('modelHomePopup');
      
    }, 3000);
    this.$nextTick(() => {
        this.$root.$on("scrolltoSection", (data) => {
          this.goToId(data.place);
        });

        setTimeout(() => {
          this.checkQuery();
        }, 3000);
      });
  },
  methods: {
    checkQuery() {
      this.query = this.$route.query;
      let hash = this.$route.query["goto"];
      console.log(hash);
      if (hash != undefined) {
        this.$root.$emit("scrolltoSection", {
          place: hash,
        });
      }
    },
    goToId(id) {
      let adjuster = 20;
      var offsetT = jq("#" + id).offset();
      if (offsetT != undefined) {
        jq("html, body").animate(
          {
            scrollTop: offsetT.top - adjuster,
          },
          500
        );
      }
    },
    panoReveal() {
      gsap.utils.toArray(".parallax").forEach((section, i) => {
        section.bg = section.querySelector(".bg");

        if (i) {
          section.bg.style.backgroundPosition = `50% ${innerHeight / 2}px`;

          gsap.to(section.bg, {
            backgroundPosition: `50% ${-innerHeight / 2}px`,
            ease: "none",
            scrollTrigger: {
              trigger: section,
              scrub: true,
            },
          });
        }
      });
    },
    initscrollSmoother_homePage() {
      this.scrollSmoother_home = ScrollSmoother.create({
        wrapper: "#home_wrapper",
        content: "#home",
        smooth: 3,
        effects: true,
      });
      this.scrollSmoother_home.effects(".plax-img", { speed: "auto" });
    },
  },
  beforeDestroy() {
    setTimeout(() => {
      // this.scrollSmoother_home.kill()
      ScrollTrigger.getAll().forEach((t) => t.kill());
    }, 500);
  },
};
</script>

<style scoped lang="scss">
#tickerTape{
  width:100%;
  z-index: 1031;
    position: fixed;
    width: 100%;
    left: 0;
}
</style>