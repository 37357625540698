<template>
    <div>
        <b-modal
            id="modelHomePopup" 
            size="lg"
            hide-footer
            hide-header
            centered
        >
            <div class="headerImg">
                <a href="javascript:;" 
                class="closePopup gothamLight" 
                @click="closePopup()">X</a>
            </div>
            <b-row>
                <b-col md="5" class="bg-blue px-lg-0 py-5">
                    <div class="acumin-pro-wide-medium color-yellow text-center h3 pt-lg-5 pt-0" style="font-weight: bold">
                        DESIGNER
                        <br>
                        DECORATED
                        <br>
                        MODEL HOME
                        <br>
                        GRAND OPENING!
                    </div>
                    <div class="mt-5">
                        <img src="/img/sb-marine-popup.jpg" alt="Marine" class="w-100 d-block">
                    </div>
                    <div class="text-white d-lg-flex justify-content-around align-items-end py-1">
                        <div class="freight-big-pro-medium">
                            THE MARINE
                        </div> 
                        <div style="font-size: 0.65rem">
                            ELEVATION C | 40' | 2,809 SQ.FT. | 2-CAR GARAGE
                        </div>
                    </div>
                    <div class="acumin-pro-wide-medium text-white px-3 d-flex justify-content-center flex-column align-items-center mt-5">
                        <div style="font-weight: bold">
                            EVENT LOCATION
                            <br>
                            <a href="https://maps.app.goo.gl/xYsHaRhTHvGLZ3RP8" class="text-white" target="_blank">
                                4 NICORT RD,
                                <br>
                                WASAGA BEACH
                            </a>
                            
                            <br>
                            PHONE: <a href="tel:289-210-7032" class="text-white" style="font-weight:normal">289.210.7032</a> OR <a href="tel:705-429-1013" class="text-white" style="font-weight:normal">705.429.1013</a>
                        </div>
                    </div>
                    <div class="pt-5 d-flex justify-content-center">
                        <img
                            src="/img/hero/FERNBROOK.svg"
                            alt="Southbay Logo"
                            class="img-fluid max-w-130"
                        />
                        <img
                            src="/img/hero/ZANCOR.svg"
                            alt="Southbay Logo"
                            class="img-fluid max-w-130 ml-4"
                        />
                    </div>
                </b-col>
                <b-col md="7" class="text-center text-blue px-0">
                    <div class="bg-cream py-5">
                        <div class="max-w-300 mx-auto">
                            <img src="/img/sb-logo-colored.png" alt="Southbay" class="w-100 d-block">
                        </div>
                        <div class="freight-big-pro-medium h1 mt-5">
                            SAVE THE DATE
                        </div>
                        <div class="max-w-480 mx-auto">
                            <div class="freight-big-pro-medium h2 mb-0 mt-5">
                                BE FIRST
                            </div>
                            <div style="font-weight:bold">
                                Explore our brand new
                                <br>
                                Designer Decorated Model Home!
                            </div>
                            <ul class="mt-3">
                                <li>
                                    Guided bus tours of Wasaga Beach every 30 minutes.
                                </li>
                                <li>
                                    Enjoy free hot chocolate or cider beachside and take in the unique SouthBay lifestyle!
                                </li>
                            </ul>
                            <div class="mt-4">
                                <span class="" style="font-weight: bold">
                                    DATE:
                                </span>
                                <br>
                                SATURDAY, OCTOBER 21<sup>st</sup>, from 11 am - 5 pm
                                <br>
                                <br>
                                <span class="" style="font-weight: bold">
                                    LOCATION:
                                </span>
                                <br>
                                <a href="https://maps.app.goo.gl/xYsHaRhTHvGLZ3RP8" target="_blank" class="text-blue">
                                    4 Nicort Road, Wasaga Beach, ON
                                </a>
                                <br>
                            </div>
                        </div>
                    </div>
                    <div>
                        <a href="https://maps.app.goo.gl/xYsHaRhTHvGLZ3RP8" target="_blank">
                            <img src="/img/popup-map.png" alt="" class="w-100">
                        </a>
                    </div>
                </b-col>
            </b-row>
        </b-modal>
    </div>
</template>

<script>
export default {
    data() {
        return {

        }
    },
    mounted() {
        
    },
    methods: {
        closePopup() {
            this.$bvModal.hide('modelHomePopup');
        }
    }
}
</script>

<style lang="scss">
#modelHomePopup {
    
    .modal-content {
        border: 0;
        border-radius: 0;
    }

    .modal-dialog {
        max-width: 1000px;
        padding: 0 15px;
        border-radius: 0;

        .modal-body {
            padding: 0;
            color: #000;

            .logo-overlay {
                position: absolute;
                top: 10%;

                @media screen and (max-width: 767px) {
                    top: 5%;
                } 
            }

            .no2 {
                font-size: 25rem;
                position: absolute;
                top: -116px;
                left: -100px;
                opacity: 0.3;
                line-height: 100%;
                pointer-events: none;

                @media screen and (max-width: 767px) {
                    top: -290px;
                    left: unset;
                    right: -70px;
                } 

                @media screen and (max-width: 480px) {
                    top: -200px;
                    font-size: 20rem;
                } 
            }

            .sm-text {
                font-size: 0.9rem;
            }

            .md-text {
                font-size: 1.5rem;
            }

            .popup-img {
                @media screen and (max-width: 767px) {
                    max-height: 320px;
                } 
            }

            .lg-text {
                font-size: 3rem;
                margin-bottom: 0;

                @media screen and (max-width: 767px) {
                    font-size: 2rem;
                } 
            }

            .closePopup {
                position: absolute;
                right: 15px;
                top: 12px;
                color: #000;
                text-decoration: none;
                transition: 0.25s ease-in-out;
                font-size: 1.25rem;
                z-index: 2;
                    
                &:hover {
                    color: #D72630;
                }

                @media screen and (max-width: 480px) {
                    top: 8px;
                } 
            }

            .vip-input {
                background-color: transparent;
                border: none;
                border-bottom: 1px solid #fff;
                width: 100%;

                &::placeholder {
                    font-family: "Gotham-Light";
                    color: #fff;
                }
            }

            .popup-btn {
                background-color: #3b2500;
                border: 2px solid #3b2500;
                color: #fff;
                text-align: center;
                display: inline-block;
                padding: 5px;
                transition: 0.3s ease-in-out;
                width: 100%;

                &:hover {
                    background-color: #cc9634;
                    transition: 0.3s ease-in-out;
                    color: #3b2500;
                    text-decoration: none;
                }
            }
        }

        .orange-gradient {
            background: rgb(209,178,114);
            background: linear-gradient(335deg, rgba(216,163,61,1) 0%, rgba(209,178,114,1) 100%);
        }
    }

    .max-w-160 {
        @media screen and (max-width: 640px) {
            max-width: 140px;
        } 
    }
}
</style>